import Vue from 'vue';
import Router from 'vue-router';
import Welcome from './views/Welcome.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkActiveClass: 'font-bold',
    routes: [
        {
            path: '/',
            component: Welcome
        }
    ]
});
export default router;
