import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDcVgmzn6GPyCnB20d4vJL6HH35D_3mA4g",
    authDomain: "immortals-game.firebaseapp.com",
    projectId: "immortals-game",
    storageBucket: "immortals-game.appspot.com",
    messagingSenderId: "1074412495693",
    appId: "1:1074412495693:web:68f8102ba494b389aacd04",
    measurementId: "G-F1YF7E63KD"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');