<template>
  <div id="page-size" class="bg1">
    <!-- Start Header -->
    <Header/>
    <!-- End Header -->

    <!-- Start Hero Banner -->
    <section class="hero-banner">
      <div class="container align-self-center">
        <div class="row">
          <div class="col-md-6 d-flex text-center">
            <div class="align-self-center">
              <img src="../assets/images/banner.png" class="desk-view" alt=""/>
              <img src="../assets/images/banner-mob.png" class="mob-view" alt=""/>
              <a href="javascript:void(0)" @click="showModal" class="play-btn1"><img src="../assets/images/play-btn.png" alt=""/></a></div>
          </div>
        </div>
      </div>
      <img src="../assets/images/shape06.png" class="shape-06" alt=""/>
    </section>
    <!-- End Hero Banner -->

    <!-- Start Welcome -->
    <section class="welcome" id="welcome">
      <div class="container align-self-center">
        <div class="row">
          <div class="col-md-8 text-center m-auto">
            <div class="title text-center mb-3">
              <span>Welcome</span>
              <h2>WARRIOR!</h2>
              <img src="../assets/images/shape03.png" alt=""/>
            </div>
            <p>Immortals is a new Puzzle RPG where players own their assets.</p>
            <p>Create your own team of six Immortals to battle in legendary arenas, earn rewards, and upgrade your characters into powerful, unique NFTs.</p>
            <p>For a very limited time, you can mint one of 10,000 genesis chests for free to secure a unique and limited Una character.</p>
            <p><img src="../assets/images/shape04.png" alt=""/></p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Welcome -->

    <!-- Start GamePlay -->
    <section class="game-play" id="game-play">
      <img src="../assets/images/shape05.png" class="shape-05" alt=""/>
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-5 order-02">
            <div class="title mb-3">
              <span>Gameplay</span>
              <h2>Collect</h2>
            </div>
            <p>As you play Immortals, you will collect trophies and cards of different characters.</p>
            <p>All Immortals fall into one of four categories: the defensive tanks, the deadly assassins, the helpful healers, and the resourceful fighters.</p>
            <p>They also come in five different classes of rarity, including common, rare, epic, legendary, and immortal!</p>
            <p>The best part? All Immortals cards are NFTs, built on the Solana blockchain, which means you own them!</p>
          </div>
          <div class="col-md-1 align-self-center hide-mob"><img src="../assets/images/ver-shape01.png" alt=""/></div>
          <div class="col-md-6 align-self-center order-01"><img src="../assets/images/collect-img.png" alt=""/></div>
        </div>

        <div class="row mb-5">
          <div class="col-md-6 align-self-center"><img src="../assets/images/mobile-mockup.png" alt=""/></div>
          <div class="col-md-1 align-self-center hide-mob"><img src="../assets/images/ver-shape02.png" alt=""/></div>
          <div class="col-md-5">
            <div class="title mb-3">
              <span>Gameplay</span>
              <h2>Evolve</h2>
            </div>
            <p>Copies of the same Immortals/NFTs you have collected can be combined into new, evolved versions of that character. This burns the original NFT, and from that fire emerges a super NFT.</p>
            <p>As you continue to evolve Immortals, they will progress along a skill tree of your choosing. This makes each Immortal completely unique.</p>
            <p>Will you keep your Immortal and see how far you can take them along their path? Or will you trade them or sell them? The choice is yours!</p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-5 order-02">
            <div class="title mb-3">
              <span>Gameplay</span>
              <h2>Battle</h2>
            </div>
            <p>When you are ready to battle, choose six Immortals from your collection.</p>
            <p>Once you have chosen your six Immortals and the arena, you will be matched in an epic puzzle battle against your opponents.</p>
            <p>If you win, you’ll receive a chest containing various types of cards used to unlock and evolve Immortals!</p>
          </div>
          <div class="col-md-1 align-self-center hide-mob"><img src="../assets/images/ver-shape01.png" alt=""/></div>
          <div class="col-md-6 align-self-center order-01"><img src="../assets/images/mobile-mockup-2.png" alt=""/></div>
        </div>

        <div class="row mb-5">
          <div class="col-md-6 align-self-center"><img src="../assets/images/coin.png" alt=""/><a href="javascript:void(0)" @click="showModal" class="play-btn1"><img src="../assets/images/play-btn.png" alt=""/></a></div>
          <div class="col-md-1 align-self-center hide-mob"><img src="../assets/images/ver-shape02.png" alt=""/></div>
          <div class="col-md-5">
            <div class="title mb-3">
              <span>Gameplay</span>
              <h2>Trade</h2>
            </div>
            <p>All Immortals cards are NFTs and can be traded on all the major marketplaces, like OpenSea. Immortals can be evolved in many unique ways, up to very powerful levels and forms. The higher you level up, and the rarer the card, the more valuable your NFT will be.</p>
            <p>Finding the right team is going to be key to victory and securing the character cards to build your team is sure to keep the trading volumes vibrant and fun.</p>
          </div>
        </div>

      </div>
      <img src="../assets/images/shape06.png" class="shape-06" alt=""/>
    </section>
    <!-- End GamePlay -->

    <!-- Start About -->
    <section class="welcome" id="about">
      <div class="container align-self-center">
        <div class="row">
          <div class="col-md-10 text-center m-auto">
            <div class="title text-center mb-3">
              <span>About</span>
              <h2>IMMORTALS</h2>
              <img src="../assets/images/shape03.png" alt=""/>
            </div>
            <p>The Immortals are heroes and villains, champions and legends of people passed on through generations, and abominable secrets hidden away. Heeding the call sent out to the vast reaches of space time, the Immortals are now emerging from every corner of every dimension to do battle and compete for total supremacy and victory.</p>
            <p>At its core, Immortals gives players an opportunity to have fun and invest in themselves at the same time. Each card can be turned into an NFT and sold on the Marketplace for <span style="color: #A180D9; font-weight: 600;">Solana</span>. The farther up the skill tree you take your character, the more unique they become, and the more valuable they become.</p>
            <h5 style="color: #A180D9; font-weight: 600;">Invest in your team, and play Immortals now!</h5>

            <p><img src="../assets/images/shape04.png" alt=""/></p>
          </div>
        </div>
      </div>
    </section>
    <!-- End About -->

    <!-- Start Who Are -->
    <section class="who-are">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title text-center mb-5">
              <h2>Who are the Immortals?</h2>
              <img src="../assets/images/shape03.png" alt=""/>
            </div>
          </div>

          <div class="col-md-6">
            <p>Come closer, let me tell you a story. Not just any story, but the story about all of the other stories, and what happens when they end.</p>
            <p>Every society of intelligent beings across every universe in each of the numberless dimensions has told stories. These stories tell of champions, heroes of ordinary or extraordinary upbringing, helping those in need, accomplishing great deeds, and fighting for what is right. They also tell of villains, those who would do ill to those around them, subjugate those they deem weak or inferior, or simply cause chaos to watch their world burn.</p>
            <p>But what if I told you these heroes and villains are real? While many of them may have lived mortal lives, they live immortally in our stories, even when those stories become lost to time and space to never be told again. This is what gives these champions and villains their name: the Immortals.</p>
            <p>While stories may fade and lives may end, the Immortals face no end to their journey and adventures. Always they have adventures, plans, schemes, and battles. Sometimes the heroes become the villains, sometimes the villains become heroes. For all the stories told of these ever-living folk, there are many more stories we have yet to hear.</p>
          </div>

          <div class="col-md-6">
            <p>Gifted special abilities by those who tell their stories, the Immortals now answer a call to all of the brave, fierce, and cunning to find the greatest among them all. They gather across time, space, and dimensions to fight, to see who will rise to the challenge, and who will falter, assembling themselves in teams, gathering in arenas to pit their skills and abilities against each other in the fight for universal, and eternal supremacy, a title which may never be unequivocally won. </p>
            <p>These arenas themselves are perilous, located on tops of the highest mountains, in the depths of the deepest seas, along the rim of black holes, in sacred temples, some even hidden in plain sight. Their battles carry consequences outside of their own existence, causing volcanoes to erupt, the Earth to tremble, the sky to roar and open up, and other disasters of unfathomable scale.</p>
            <p>Nobody knows when or where the contest of champions started, but one thing is certain: the number of Immortals heeding the call and accepting the challenge is quickly growing. Some are famous heroes and villains, and some unexpected individuals who should not be underestimated. As they continue on their journey of eternity, they seek aid in rising to the top, and are extending the call now to you to join the ranks of the heroes, villains, assassins, gods, and creatures.</p>
            <p>The Immortals are battling for supremacy, many are heeding their call. Will you?</p>
          </div>

          <div class="col-md-12 text-center"><a href="javascript:void(0)" @click="showModal" class="play-btn1 mt-5"><img src="../assets/images/play-btn.png" alt=""/></a></div>

        </div>
      </div>
      <img src="../assets/images/shape06.png" class="shape-06" alt="">
    </section>
    <!-- End Who Are -->

    <!-- Start Player -->
    <section class="player-img">
      <div class="container-fluid p-0 m-slide">
        <carousel :stagePadding="0" :margin="0" :items="2" :loop="false" :autoplay="false" :dots="false" :nav="false">
          <div class="item">
            <span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/> <img src="../assets/images/player-img-01.png" alt=""/></span>
          </div>
          <div class="item">
            <span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-02.png" alt=""/></span>
          </div>
          <div class="item">
            <span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-03.png" alt=""/></span>
          </div>
          <div class="item">
            <span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-04.png" alt=""/></span>
          </div>
          <div class="item">
            <span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-05.png" alt=""/></span>
          </div>
        </carousel>

      </div>
      <div class="container-fluid p-0 d-slide">
        <ul>
          <li><span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/> <img src="../assets/images/player-img-01.png" alt=""/></span></li>
          <li><span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-02.png" alt=""/></span></li>
          <li><span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-03.png" alt=""/></span></li>
          <li><span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-04.png" alt=""/></span></li>
          <li><span><img src="../assets/images/Immortals-logo.png" class="immortals-logo" alt=""/><img src="../assets/images/player-img-05.png" alt=""/></span></li>
        </ul>
      </div>
    </section>
    <!-- End Player -->

    <!-- Start Subscribe -->
    <section class="welcome">
      <img src="../assets/images/shape05.png" class="shape-05" alt="">
      <div class="container align-self-center">
        <div class="row">
          <div class="col-md-10 text-center m-auto">
            <div class="title text-center mb-3">
              <span>Subscribe</span>
              <h2>Follow us for more <br>
                IMMORTALS updates</h2>
              <img src="../assets/images/shape03.png" alt=""/>
            </div>
            <div class="social-links">
              <p>Join our Discord & Social Media</p>
              <p>
                <a href="https://discord.gg/immortalsgame" target="_blank"><img src="../assets/images/discord-logo.png" alt=""/></a>
                <a href="https://twitter.com/immortals_play" target="_blank"><img src="../assets/images/twitter-icon.png" alt=""/></a>
                <a href="https://www.instagram.com/immortals_nft" target="_blank"><img src="../assets/images/instagram_icon.png" alt=""/></a>
              </p>
            </div>
            <p><img src="../assets/images/shape04.png" alt=""/></p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Subscribe -->
    <Modal
        v-show="isModalVisible"
        @close="closeModal"
    />
    <Footer/>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Modal from '../components/Modal.vue'

export default {
  name: 'Welcome',
  components: {
    carousel,
    Header,
    Footer,
    Modal
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>
