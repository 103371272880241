<template>
  <header class="header">
    <div class="container justify-content-between d-flex">
      <div class="logo align-self-center"><img src="../assets/images/immortals-icon.png" alt=""/></div>

      <button type="button" class="burger-menu" id="burger" v-on:click="isHidden = !isHidden">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div class="overlay active" id="overlay" v-on:click="isHidden = !isHidden" v-if="!isHidden">
      </div>
      <nav class="navbar active" id="navbar" v-if="!isHidden">
        <ul class="menu">
          <li class="menu-item"><a href="#welcome" v-on:click="isHidden = !isHidden" v-if="!isHidden">Welcome</a></li>
          <li class="menu-item"><a href="#game-play" v-on:click="isHidden = !isHidden" v-if="!isHidden">Gameplay</a></li>
          <li class="menu-item"><a href="#about" v-on:click="isHidden = !isHidden" v-if="!isHidden">About</a></li>
          <li class="menu-item"><a href="javascript:void(0)" @click="showModal" v-on:click="isHidden = !isHidden" v-if="!isHidden">Connect</a></li>
        </ul>
      </nav>
      <nav class="navbar desktop_menu">
        <ul class="menu">
          <li class="menu-item"><a href="#welcome">Welcome</a></li>
          <li class="menu-item"><a href="#game-play">Gameplay</a></li>
          <li class="menu-item"><a href="#about">About</a></li>
          <li class="menu-item"><a href="javascript:void(0)" @click="showModal">Connect</a></li>
        </ul>
      </nav>
      <div class="play-btn align-self-center"><a href="javascript:void(0)" @click="showModal"><img src="../assets/images/play-btn.png" alt=""/></a></div>
      <Modal
          v-show="isModalVisible"
          @close="closeModal"
      />
    </div>
  </header>
</template>

<script>
import Modal from "./Modal";

export default {
  name: 'Header',
  components: {
    Modal
  },
  data() {
    return {
      isHidden: true,
      isModalVisible: false
    };
  },
  props: {
    msg: String
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
}
</script>
