<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">© IMMORTALS 2022. All rights reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>