<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
  @import url("./assets/css/bootstrap.min.css");
  @import url("./assets/css/custom.css");
  @import url("./assets/css/menu-css.css");  
</style>
